const Google = {
  async GoogleLoginSuccess(googleUser) {
    console.log('Google: ', googleUser)
    let jwt
    for (const i in googleUser) {
      if (googleUser[i].id_token) jwt = googleUser[i].id_token
    }
    const req_body = {
      jwt,
    }
    return new Promise((resolve) => {
      resolve(req_body)
    })
  },

  GoogleLoginFailure() {
    console.log('fail')
  },
}

export default Google
